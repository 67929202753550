.filters {
  display: flex;
  justify-content: space-around;
  background: #e7e7e7;
  padding: 10px;
  border-radius: 10px;

  label {
    align-self: center;
    font-weight: 600;
    color: #767676;
  }

  button {
    align-self: center;
  }
}

#ConsultasRealizadas {
  table {
    tr {
      td {
        &:nth-child(7) {
          label {
            &:first-child {
              font-size: 1.4em;
            }
          }

          background: #f8eeff;
          border-bottom: #dfdfdf solid 1px;
          color: #6277c3;
        }
      }
    }
  }
}

#ConsultasEmEspera {
  table {
    tr {
      td {
        &:nth-child(6) {
          label {
            &:first-child {
              font-size: 1.4em;
            }
          }

          background: #f8eeff;
          border-bottom: #dfdfdf solid 1px;
          color: #6277c3;
        }
      }
    }
  }
}

.formulario {
  .ant-row {
    background: #f2f6ff;
    padding: 10px;
    border-radius: 10px;

    .ant-row.ant-form-item {
      margin-bottom: 0;

      label {
        font-weight: 500;
        color: #27354c;
      }
    }
  }
}
