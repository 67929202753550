.contrato {
  .versao-card-border-less-wrapper {
    padding: 30px;
    background: #ececec;

    .ant-card-body {
      p {
        span {
          font-weight: 600;
        }

        display: flex;
        justify-content: space-between;
      }

      p:first-child {
        justify-content: center;
      }
    }
  }

  .ant-pro-card {
    background: #f7f7f7;
    border: 1px solid #e5e5e5;
  }

  .contrato-versao-visualizar {
    > p {
      display: flex;
      text-align: left;

      span:first-child {
        width: 100%;
        max-width: 200px;
      }

      span:last-child {
        margin-left: 10px;
      }
    }

    .ant-typography,
    .ant-collapse {
      margin-top: 20px;
    }

    .ant-collapse-header {
      display: flex;
      padding: 10px;
      background: #e9e6e6;
      margin: -10px;
    }

    .site-collapse-custom-collapse .site-collapse-custom-panel {
      margin-bottom: 24px;
      overflow: hidden;
      border: 0;
      border-radius: 2px;
      padding: 10px;
    }

    .bg-cyan-2 {
      background: #b5f5ec;
    }

    .site-collapse-custom-panel {
      background: #f7f7f7;
    }

    .ant-collapse-content {
      margin-top: 20px;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
}

.avaliacoes {
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background: #fafafa;
}
