.materiais {
  line-height: 40px;
  column-count: 4;
}

.input {
  max-width: 50px;
}

.proCard {
  line-height: 1.6;
}

.control {
  text-align: center;
}

.arcadas {
  background: white;
  margin: 0 20px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  width: 90%;

  .options {
    background: #fbfbfb;
    margin: -10px;
    margin-bottom: 20px;
    text-align: center;

    button {
      &:hover,
      &:active,
      &.active {
        background: #1990ff;
        color: white;
      }

      margin: 10px;
      height: unset;
      border-radius: 10px;
      color: #87878b;
      border: none;
      box-shadow: 0 0 3px -1px #898989;
      font-weight: 600;
    }
  }

  .dentes {
    &.boca {
      background: #fdfdfd;
      box-shadow: inset 0 0 1px 1px #a6c2ff;
      border-radius: 11px;
    }

    &.arcada {
      .linha {
        background: #fdfdfd;
        padding: 13px;
        border-radius: 11px;
        box-shadow: inset 0 0 1px 1px #a6c2ff;
      }

      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }
    }

    &.quadrante {
      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }

      .linha {
        .bloco {
          background: #fdfdfd;
          box-shadow: inset 0 0 1px 1px #a6c2ff;
          border-radius: 11px;
          padding: 13px;
        }

        .bloco:first-child {
          margin-right: 15px;
        }

        .bloco:last-child {
          margin-left: 15px;
        }
      }
    }

    &.dente {
      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }

      .linha {
        .bloco {
          img {
            margin-right: 2px;
            margin-left: 2px;
            background: #fff;
            box-shadow: 0 1px 2px 0 #6eb4ff;
            border-radius: 11px;
            padding: 5px;
            width: 32px;

            &:hover,
            &:active,
            &.active {
              background: #6eb4ff;
            }
          }
        }
      }
    }

    margin: 10px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 150px;
    opacity: 0.7;

    .linha {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      transition: all 0.5s linear;

      .bloco {
        transition: all 0.5s linear;
        display: flex;
        flex-wrap: nowrap;

        img {
          transition: all 0.5s linear;
          cursor: pointer;
        }
      }
    }
  }
}
