@import "functions";

// Color system

$primary-color: #1890ff !default;
$white-color: #fff !default;
$black-color: #000 !default;
$cyan-color: #008bd2 !default;
$blue-color: #004f9f !default;
$indigo-color: #5c2483 !default;
$purple-color: #823f91 !default;
$pink-color: #e6007e !default;
$red-color: #be1622 !default;
$volcano-color: #e94e24 !default;
$orange-color: #f59c00 !default;
$yellow-color: #fdc300 !default;
$lime-color: #95c11f !default;
$green-color: #00983a !default;
$gray-1: #f8f9fa !default;
$gray-2: #e9ecef !default;
$gray-3: #dee2e6 !default;
$gray-4: #ced4da !default;
$gray-5: #adb5bd !default;
$gray-6: #6c757d !default;
$gray-7: #495057 !default;
$gray-8: #343a40 !default;
$gray-9: #212529 !default;
$primary-1: tint-color($primary-color, 80%) !default;
$primary-2: tint-color($primary-color, 60%) !default;
$primary-3: tint-color($primary-color, 40%) !default;
$primary-4: tint-color($primary-color, 20%) !default;
$primary-5: $primary-color !default;
$primary-6: shade-color($primary-color, 20%) !default;
$primary-7: shade-color($primary-color, 40%) !default;
$primary-8: shade-color($primary-color, 60%) !default;
$primary-9: shade-color($primary-color, 80%) !default;
$cyan-1: tint-color($cyan-color, 80%) !default;
$cyan-2: tint-color($cyan-color, 60%) !default;
$cyan-3: tint-color($cyan-color, 40%) !default;
$cyan-4: tint-color($cyan-color, 20%) !default;
$cyan-5: $cyan-color !default;
$cyan-6: shade-color($cyan-color, 20%) !default;
$cyan-7: shade-color($cyan-color, 40%) !default;
$cyan-8: shade-color($cyan-color, 60%) !default;
$cyan-9: shade-color($cyan-color, 80%) !default;
$blue-1: tint-color($blue-color, 80%) !default;
$blue-2: tint-color($blue-color, 60%) !default;
$blue-3: tint-color($blue-color, 40%) !default;
$blue-4: tint-color($blue-color, 20%) !default;
$blue-5: $blue-color !default;
$blue-6: shade-color($blue-color, 20%) !default;
$blue-7: shade-color($blue-color, 40%) !default;
$blue-8: shade-color($blue-color, 60%) !default;
$blue-9: shade-color($blue-color, 80%) !default;
$indigo-1: tint-color($indigo-color, 80%) !default;
$indigo-2: tint-color($indigo-color, 60%) !default;
$indigo-3: tint-color($indigo-color, 40%) !default;
$indigo-4: tint-color($indigo-color, 20%) !default;
$indigo-5: $indigo-color !default;
$indigo-6: shade-color($indigo-color, 20%) !default;
$indigo-7: shade-color($indigo-color, 40%) !default;
$indigo-8: shade-color($indigo-color, 60%) !default;
$indigo-9: shade-color($indigo-color, 80%) !default;
$purple-1: tint-color($purple-color, 80%) !default;
$purple-2: tint-color($purple-color, 60%) !default;
$purple-3: tint-color($purple-color, 40%) !default;
$purple-4: tint-color($purple-color, 20%) !default;
$purple-5: $purple-color !default;
$purple-6: shade-color($purple-color, 20%) !default;
$purple-7: shade-color($purple-color, 40%) !default;
$purple-8: shade-color($purple-color, 60%) !default;
$purple-9: shade-color($purple-color, 80%) !default;
$pink-1: tint-color($pink-color, 80%) !default;
$pink-2: tint-color($pink-color, 60%) !default;
$pink-3: tint-color($pink-color, 40%) !default;
$pink-4: tint-color($pink-color, 20%) !default;
$pink-5: $pink-color !default;
$pink-6: shade-color($pink-color, 20%) !default;
$pink-7: shade-color($pink-color, 40%) !default;
$pink-8: shade-color($pink-color, 60%) !default;
$pink-9: shade-color($pink-color, 80%) !default;
$red-1: tint-color($red-color, 80%) !default;
$red-2: tint-color($red-color, 60%) !default;
$red-3: tint-color($red-color, 40%) !default;
$red-4: tint-color($red-color, 20%) !default;
$red-5: $red-color !default;
$red-6: shade-color($red-color, 20%) !default;
$red-7: shade-color($red-color, 40%) !default;
$red-8: shade-color($red-color, 60%) !default;
$red-9: shade-color($red-color, 80%) !default;
$volcano-1: tint-color($volcano-color, 80%) !default;
$volcano-2: tint-color($volcano-color, 60%) !default;
$volcano-3: tint-color($volcano-color, 40%) !default;
$volcano-4: tint-color($volcano-color, 20%) !default;
$volcano-5: $volcano-color !default;
$volcano-6: shade-color($volcano-color, 20%) !default;
$volcano-7: shade-color($volcano-color, 40%) !default;
$volcano-8: shade-color($volcano-color, 60%) !default;
$volcano-9: shade-color($volcano-color, 80%) !default;
$orange-1: tint-color($orange-color, 80%) !default;
$orange-2: tint-color($orange-color, 60%) !default;
$orange-3: tint-color($orange-color, 40%) !default;
$orange-4: tint-color($orange-color, 20%) !default;
$orange-5: $orange-color !default;
$orange-6: shade-color($orange-color, 20%) !default;
$orange-7: shade-color($orange-color, 40%) !default;
$orange-8: shade-color($orange-color, 60%) !default;
$orange-9: shade-color($orange-color, 80%) !default;
$yellow-1: tint-color($yellow-color, 80%) !default;
$yellow-2: tint-color($yellow-color, 60%) !default;
$yellow-3: tint-color($yellow-color, 40%) !default;
$yellow-4: tint-color($yellow-color, 20%) !default;
$yellow-5: $yellow-color !default;
$yellow-6: shade-color($yellow-color, 20%) !default;
$yellow-7: shade-color($yellow-color, 40%) !default;
$yellow-8: shade-color($yellow-color, 60%) !default;
$yellow-9: shade-color($yellow-color, 80%) !default;
$lime-1: tint-color($lime-color, 80%) !default;
$lime-2: tint-color($lime-color, 60%) !default;
$lime-3: tint-color($lime-color, 40%) !default;
$lime-4: tint-color($lime-color, 20%) !default;
$lime-5: $lime-color !default;
$lime-6: shade-color($lime-color, 20%) !default;
$lime-7: shade-color($lime-color, 40%) !default;
$lime-8: shade-color($lime-color, 60%) !default;
$lime-9: shade-color($lime-color, 80%) !default;
$green-1: tint-color($green-color, 80%) !default;
$green-2: tint-color($green-color, 60%) !default;
$green-3: tint-color($green-color, 40%) !default;
$green-4: tint-color($green-color, 20%) !default;
$green-5: $green-color !default;
$green-6: shade-color($green-color, 20%) !default;
$green-7: shade-color($green-color, 40%) !default;
$green-8: shade-color($green-color, 60%) !default;
$green-9: shade-color($green-color, 80%) !default;

// Screen size

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-sm: 576px !default;
$screen-sm-min: $screen-sm !default;
$screen-md: 768px !default;
$screen-md-min: $screen-md !default;
$screen-lg: 992px !default;
$screen-lg-min: $screen-lg !default;
$screen-xl: 1200px !default;
$screen-xl-min: $screen-xl !default;
$screen-xxl: 1600px !default;
$screen-xxl-min: $screen-xxl !default;
$screen-xs-max: ($screen-sm-min - 1px) !default;
$screen-sm-max: ($screen-md-min - 1px) !default;
$screen-md-max: ($screen-lg-min - 1px) !default;
$screen-lg-max: ($screen-xl-min - 1px) !default;
$screen-xl-max: ($screen-xxl-min - 1px) !default;
