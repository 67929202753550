@import "../../styles/variables";

.container > * {
  border-radius: 4px;

  // box-shadow: $shadow-1-down;
}

@media screen and (max-width: $screen-xs) {
  .container {
    width: 100% !important;
  }

  .container > * {
    border-radius: 0 !important;
  }
}
