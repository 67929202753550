.filters {
  display: flex;
  justify-content: space-around;
  background: #e7e7e7;
  padding: 10px;
  border-radius: 10px;

  label {
    align-self: center;
    font-weight: 600;
    color: #767676;
  }

  button {
    align-self: center;
  }
}

.oracamentoContent {
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
}

.oracamentoDetalhes {
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    margin-bottom: 24px;
    overflow: hidden;
    border: 0;
    border-radius: 2px;
    padding: 10px;
  }

  .ant-collapse-header {
    display: flex;
    padding: 10px;
    background: #e9e6e6;
    margin: -10px;
  }

  .ant-collapse-content-box {
    margin-top: 10px;
  }

  .site-collapse-custom-panel {
    background: #f7f7f7;
  }

  .versao-card-border-less-wrapper {
    padding: 30px;
    background: #ececec;

    .ant-card-body {
      p {
        span {
          font-weight: 600;
        }

        display: flex;
        justify-content: space-between;
      }

      p:first-child {
        justify-content: center;
      }
    }
  }

  .ant-pro-card {
    background: #f7f7f7;
    border: 1px solid #e5e5e5;
  }

  .contrato-versao-visualizar {
    > p {
      display: flex;
      text-align: left;

      span:first-child {
        width: 100%;
        max-width: 200px;
      }

      span:last-child {
        margin-left: 10px;
      }
    }

    .ant-typography,
    .ant-collapse {
      margin-top: 20px;
    }

    .bg-cyan-2 {
      background: #b5f5ec;
    }

    .ant-collapse-content {
      margin-top: 20px;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
}

.parcelasDetalhes {
  p {
    text-align: center;
    font-weight: bolder;
    color: #898989;
    margin-top: 20px;
  }

  .ant-card-head-title {
    color: #898989;
  }

  table {
    font-size: 12px;
  }

  .ant-page-header {
    padding: 0 10px;
    background: rgb(250 250 250);
    border-radius: 0;
    margin-bottom: 20px;
    margin-left: -24px;
    padding-left: 40px;

    .ant-page-header-heading-title {
      font-weight: unset;
      font-size: 15px;
      text-transform: uppercase;
      border-bottom: 2px solid #1990ff;
      line-height: 1.3;
      color: #5c5c5c;
    }

    .ant-page-header-heading-sub-title {
      font-size: 15px;
      text-transform: uppercase;
    }
  }

  .site-card-border-less-wrapper {
    padding: 30px;
    background: #fafafa;
  }

  .title {
    font-size: 15px;
    font-weight: lighter;
  }

  .formas-pagmentos {
    background: #fafafa;
  }

  .table-payment {
    background: #f3f3f3;
    width: 100%;
    border-radius: 2px;
    padding: 10px;
    margin: 0 auto;

    th {
      padding: 10px;
    }

    td {
      padding: 2px 10px;
    }
  }
}

#customTableParcelas {
  tr {
    td {
      &:nth-child(1) {
        background: #fafafa;
      }

      &:nth-child(2) {
        background: #fafafa;
        color: #3e3e3e;
        font-weight: 700;
        border-right: 1px solid #e3e3e3;
      }
    }
  }
}

.bg-green {
  background: #f6ffec;
}
