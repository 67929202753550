.novo-orcamento {
  display: flex;

  .procedimentos {
    width: 25%;

    .header {
      background: #f5f5f5;
      padding: 10px;
      font-size: 1em;
      font-weight: 600;
      color: #838383;
    }

    .search {
      display: flex;
      justify-content: right;

      .search-box {
        border-radius: 50px;
        display: flex;
        width: 60%;
        justify-content: right;
        margin-bottom: 10px;
        margin-top: 10px;

        .search-btn {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 2s;
          padding: 10px;
          font-size: 1.4em;
          margin-right: 10px;
          margin-left: 10px;
        }

        .search-input {
          padding: 0 10px;
          width: 0;
          font-size: 1em;
          transition: 0.4s;
          outline: none;
          border-radius: 10px;
          background: transparent;
          border: 1px solid transparent;
        }
      }

      .search-box:hover > .search-input {
        border-color: #1890ff;
        width: 100%;
      }
    }

    max-width: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 3px 4px #e8eaed;
    overflow: hidden;

    .list {
      max-height: 36.1vw;
      min-height: 36.1vw;
      overflow-x: auto;

      & > strong {
        color: #48a8f1;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        small {
          margin-right: 18px;
        }
      }

      ul {
        padding: 3px;
        margin: 0 auto;
        text-align: center;
        width: 90%;
        list-style: none;
        background: #f2f5f9;
        border-radius: 10px;
        margin-bottom: 10px;

        li {
          cursor: pointer;
          background: #fff;
          font-size: 0.8em;
          font-weight: 700;
          margin: 10px 5px;
          border-radius: 6px;
          padding: 10px;
          color: #48a8f1;
          border-left: 3px solid #6db4ff;
          box-shadow: 0 0 0 1px #6db4ff;
          transition: all 0.5s linear;

          &:hover {
            background: #6db4ff;
            color: #fff;
          }
        }
      }
    }
  }
}

.header-tab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
  width: 100%;

  .ant-btn-primary {
    position: absolute;
    color: #2d86bc !important;
    border-color: transparent !important;
    background: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    z-index: 50;

    &:first-child {
      left: 10px;
      top: 15px;
    }

    &:last-child {
      right: 10px;
      top: 15px;
    }

    * {
      z-index: 100;
    }
  }
}

.arcadas {
  background: white;
  margin: 0 20px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  width: 55%;

  .options {
    background: #fbfbfb;
    margin: -10px;
    margin-bottom: 20px;
    text-align: center;

    button {
      &:hover,
      &:active,
      &.active {
        background: #1990ff;
        color: white;
      }

      margin: 10px;
      height: unset;
      border-radius: 10px;
      color: #87878b;
      border: none;
      box-shadow: 0 0 3px -1px #898989;
      font-weight: 600;
    }
  }

  .dentes {
    &.boca {
      background: #fdfdfd;
      box-shadow: inset 0 0 1px 1px #a6c2ff;
      border-radius: 11px;
    }

    &.arcada {
      .linha {
        background: #fdfdfd;
        padding: 13px;
        border-radius: 11px;
        box-shadow: inset 0 0 1px 1px #a6c2ff;
      }

      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }
    }

    &.quadrante {
      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }

      .linha {
        .bloco {
          background: #fdfdfd;
          box-shadow: inset 0 0 1px 1px #a6c2ff;
          border-radius: 11px;
          padding: 13px;
        }

        .bloco:first-child {
          margin-right: 15px;
        }

        .bloco:last-child {
          margin-left: 15px;
        }
      }
    }

    &.dente {
      .superior {
        margin-bottom: 15px;
      }

      .inferior {
        margin-top: 15px;
      }

      .linha {
        .bloco {
          img {
            margin-right: 2px;
            margin-left: 2px;
            background: #fff;
            box-shadow: 0 1px 2px 0 #6eb4ff;
            border-radius: 11px;
            padding: 5px;
            width: 32px;

            &:hover,
            &:active,
            &.active {
              background: #6eb4ff;
            }
          }
        }
      }
    }

    margin: 10px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 15px;
    height: 247px;
    opacity: 0.7;

    .linha {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      transition: all 0.5s linear;

      .bloco {
        transition: all 0.5s linear;
        display: flex;
        flex-wrap: nowrap;

        img {
          transition: all 0.5s linear;
          cursor: pointer;
        }
      }
    }
  }
}

.checkout {
  background: white;
  width: 39%;
  display: flex;
  flex-flow: column nowrap;
  padding: 11px;
  border-radius: 5px;

  ul {
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #e9e9e921;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 #e1e0e0;

    li {
      border-left: 4px solid #006bdd;
      display: flex;
      flex-wrap: nowrap;
      padding: 10px;
      margin-top: 10px;
      background: #fff;
      margin-left: 10px;
      margin-right: 10px;
      position: relative;
      box-shadow: 0 0 2px 0 #bfe1ff;
      border-radius: 10px;
      color: #395e7e;

      .item-description {
        margin-left: 10px;
      }

      p {
        font-size: 0.8em;
        margin: 0;
      }

      .anticon {
        font-size: 25px;
        color: #ddd;
      }

      .item-price {
        position: absolute;
        right: 25px;
        bottom: 10px;
      }
    }
  }

  .total {
    padding: 10px;
    text-align: end;
    font-weight: 500;
    color: #0d85f5;

    &:last-child {
      display: flex;
      justify-content: space-between;
    }
  }
}

.image-raio-x-contents {
  margin-top: 40px;
  padding: 12px;
  background: #ededed;
  border-radius: 10px;

  .ant-image {
    margin: 10px;
  }
}

.tab-content-odoto {
  margin: 10px;
  margin-top: 40px;
  background: #eff2f569;
  padding: 10px;
  border-radius: 10px;

  .ant-tabs-tab {
    font-weight: 600;
  }

  textarea {
    border-radius: 10px;
    background: white;
    border: none;
    box-shadow: inset 0 0 1px 1px #e9e9e9;
  }
}

.ant-image-preview-operations {
  background: #00000096;
}

.ant-image-preview-mask {
  background: #5c676d4f;
}
