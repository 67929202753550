// Forma de Pagamento

.formaPagamentoInstituicao {
  margin: 0 0 0 20px;
  padding: 0;
}

.ulCondicoes {
  margin: 0 0 20px 20px;
  padding: 0;
}

// Raio-X
.well.warning {
  background-color: #f89406;
  color: #fff;
  font-size: 13px;
  margin-bottom: 20px;
  min-height: 20px;
  padding: 19px;
}
