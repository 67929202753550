@import "variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

iframe.legado {
  background: #fff;
  display: block; /* iframes are inline by default */
  position: fixed;
  border: none;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 56px);
}

img.ant-pro-sider-menu-icon {
  max-height: 25px;
  max-width: 25px;
}

.select-pacientes-item {
  .direita {
    font-size: 0.85em;
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
      monospace;

    div {
      text-align: right;
    }
  }
}

.menu-personalizado {
  .ant-menu-horizontal:not(.ant-menu-dark) {
    & > .ant-menu-item,
    & > .ant-menu-submenu {
      padding: 0 10px;
    }
  }

  .ant-menu-submenu-title {
    padding: 0;
  }
}

.menu-popover-personalizado {
  .ant-menu-item a {
    color: $black-color;

    &:hover,
    &:active {
      color: $gray-6;
    }
  }
}
