#divListaEspera {
  width: 100%;
  display: block;

  .raioX {
    width: 48%;
    margin-right: 4%;
    float: left;
  }

  .raioXPago {
    width: 48%;
    float: left;
  }
}

.formas-pagmentos {
  background: #fafafa;
}

.table-payment {
  background: #f3f3f3;
  width: 100%;
  border-radius: 2px;
  padding: 10px;
  margin: 0 auto;

  th {
    padding: 10px;
  }

  td {
    padding: 2px 10px;
  }
}

.fieldset-danger {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
  padding: 8px 35px 8px 14px;
  border-radius: 4px;
  margin-bottom: 18px;
  text-align: center;
}
