@import "../../styles/variables";

$pro-header-hover-bg: rgb(0 0 0 / 2.5%);

.menu {
  border-inline-end: none !important;

  .item {
    min-width: 160px;

    .icon {
      margin-right: 8px;
    }
  }
}

.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;

  .action {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;

    > span {
      vertical-align: middle;
    }

    &:hover {
      background: $pro-header-hover-bg;
    }

    .opened {
      background: $pro-header-hover-bg;
    }
  }

  .search {
    padding: 0 12px;

    &:hover {
      background: transparent;
    }
  }

  .account {
    .avatar {
      margin-right: 8px;
      color: $primary-color;
      vertical-align: top;
      background: $white-color;
    }
  }
}

.dark {
  .action {
    &:hover {
      background: #252a3d;
    }

    .opened {
      background: #252a3d;
    }
  }
}
