.btn-info{
    color: #096dd9;
    border-color: #096dd9;
}
.btn-info:hover {
    color: #fff;
    background-color: #31d2f2;
    border-color: #25cff2;
}
.listaParcelas{
    width: 100%;
    border: .1em solid #ddd;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}
.listaParcelas .ant-card-head{
    border-bottom: .1em solid #ddd;
    font-weight: bold;
}
.ant-picker{
    width: 100%;
}
.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}
.MarginBottom0FormInput,
.MarginBottom0FormInput .ant-form-item{
    margin-bottom: 0px;
}
.btn-danger{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
}
.txt-danger{
    color: #dc3545;
}
.txt-danger:hover{
    color: #b02a37;;
}